import React from "react"
import { css } from "@emotion/core"
import Layout from "../../components/layout"
import SEO from "../../components/seo/seo"
import TwFrame from "../../components/Style/TwFrame"



export default ({ data }) => {
  return (
    <Layout>
        <SEO  title="Impressum" description="Impressum der Privatpraxis für Dermatologie, Allergologie und Lasertherapie Prof. Dr. med. Peter Michael Kövary für die Website praxis-hautarzt.de."/>
        <TwFrame > 
      <div>
        <h1
          css={css`
            display: inline-block;
            border-bottom: 1px solid;
          `}
        >
         <span style={{color: '#CDCDCD'}}>Impressum</span>
          <p           css={css`
            color: rgba(0, 0, 0, 0.54);    
            font-size: 24px;        
          `}>
          der Privatpraxis für Dermatologie, Allergologie und Lasertherapie Prof. Dr. med. Peter Michael Kövary für die Website praxis-hautarzt.de und die zugehörigen Social Media Seiten bei Facebook, Twitter und Instagramm.
          </p>
        </h1>
        <article id="Impressum" style={{textAlign: 'justify'}}>
       
        <p>Angaben gemäß § 5 TMG:</p>
        <p>
          Prof. Dr. med. Peter Michael Kövary<br />
          Norderoog 1<br />
          28259 Bremen
        </p>
        <h2><span style={{color: '#CDCDCD'}}>Kontakt:</span></h2>
        <p>
          Email: info@allab.de
          Telefon: 0421- 520 79 575 <br />
          Telefax:0421- 520 79 576 <br />
          E-Mail: info@alab.de
        </p>
        <p>Berufsbezeichnung: Arzt verliehen in der Bundesrepublik Deutschland.</p>
        <h2><span style={{color: '#CDCDCD'}}>Zugehörigkeit zu berufsständischen Institutionen:</span></h2>
        <p>
          Landesärztekammer Bremen<br />
          Schwachhauser Heerstraße 30<br />
          28209 Bremen<br />
          Telefon: +49  (0) 421 3404200<br />
          <a href="https://www.aekhb.de/">www.aekhb.de</a>
        </p>
        <h2><span style={{color: '#CDCDCD'}}>Berufsrechtliche Regelungen:</span></h2>
        <p>
          Es gilt die Berufsordnung der Landesärztekammer Bremen <a href="https://www.aekhb.de/data/mediapool/ae_re_rg_berufsordnung.pdf">https://www.aekhb.de/data/mediapool/ae_re_rg_berufsordnung.pdf</a>
          Heilberufegesetz des Landes Bremen <a href="https://www.transparenz.bremen.de/sixcms/detail.php?gsid=bremen2014_tp.c.67302.de&asl=bremen203_tpgesetz.c.55340.de&template=20_gp_ifg_meta_detail_d">https://www.transparenz.bremen.de/sixcms/detail.php?gsid=bremen2014_tp.c.67302.de&amp;asl=bremen203_tpgesetz.c.55340.de&amp;template=20_gp_ifg_meta_detail_d</a>.<br />
          Die Regelungen finden Sie auf der Homepage der Landesärztekammer Bremen (<a href="https://www.aekhb.de/">www.aekhb.de</a>)
          in der Rubrik Ärzte - Recht - Rechtsgrundlagen (<a href="https://www.aekhb.de/aerzte/recht/rechtsgrundlagen/3/26/index.html"> https://www.aekhb.de/aerzte/recht/rechtsgrundlagen/3/26/index.html</a>).
        </p>
        <h2><span style={{color: '#CDCDCD'}}>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</span></h2>
        <p>
          Prof. Dr. med. Peter Michael Kövary<br />
        </p>
        <p>Die Impressumsangaben gelten auch für meine Profile in den sozialen Netzwerken Facebook, Instagram und Twitter.</p>
        <h2><span style={{color: '#CDCDCD'}} id="Bildrechte">Quellenangaben für die verwendeten Bilder und Grafiken:</span></h2>
        <p>
          Die verwendeteten Bilder sind bei <a href="www.istock.com">iStock</a>iStock lizenziert und unterliegen alle einem Copyright des jeweilgen Fotografen.
          <br /> Das Herunterladen, einbetten oder jegliche sonstiges unauthorisierte Nutzung ist untersagt.
        </p>
        <em>Gemäß § 28 BDSG widerspreche ich jeder kommerziellen Verwendung und Weitergabe meiner Daten.</em>

        Informationspflicht gem. Verbraucherstreitbeilegungsgesetz (§ 36 VSBG) Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.
      </article>

      </div>
      </TwFrame > 
    </Layout>
  )
}