import React from "react"
import PropTypes from 'prop-types';
import { css } from "@emotion/core"





const TwFrame = ({ children }) => {
  return (
    <div className="tw-left-auto tw-pl-3 tw-prose tw-prose-sm sm:tw-prose-sm tw-prose-lg lg:tw-prose-lg tw-prose-xl xl:tw-rose-xl " > 
            {children} 
    </div>
  );

};

TwFrame.propTypes = {

  children: PropTypes.node.isRequired,

};

export default TwFrame;